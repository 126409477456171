var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('columns',[_c('column',{staticClass:"has-text-right"},[_c('router-link',{staticClass:"button is-small is-rounded",attrs:{"to":{
                name: 'create-report-widget',
                params: {
                    report: _vm.$route.params.report
                }}}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Widget")])],1)],1)],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.report.widgets),function(widget){return _c('div',{key:widget.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{attrs:{"to":{
                                name: 'edit-report-widget',
                                params: {
                                    report: _vm.$route.params.report,
                                    widget: widget.id
                                }
                            }}},[_vm._v(" "+_vm._s(widget.title)+" ")])],1)],1)],1)}),0),_c('no-items-to-display',{attrs:{"item-count":_vm.report.widgets.length,"with-link":"","heading":"There are no widgets available for display.","to":{
                name: 'create-report-widget',
                params: {
                    report: _vm.$route.params.report
                }
            },"to-text":"New Report Widget"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }