<template>
<div>
    <columns>
        <column class="has-text-right">
            <router-link class="button is-small is-rounded" :to="{
                name: 'create-report-widget',
                params: {
                    report: $route.params.report
                }}">
                <icon icon="plus"/>
                <span>Widget</span>
            </router-link>
        </column>
    </columns>
    
    <columns>
        <column>
            <div class="index-rows">
                <div class="box is-marginless" v-for="widget in report.widgets" :key="widget.id">
                    <columns>
                        <column>
                            <router-link :to="{
                                name: 'edit-report-widget',
                                params: {
                                    report: $route.params.report,
                                    widget: widget.id
                                }
                            }">
                                {{ widget.title }}
                            </router-link>
                        </column>
                    </columns>
                </div>
            </div>

            <no-items-to-display :item-count="report.widgets.length" with-link heading="There are no widgets available for display." :to="{
                name: 'create-report-widget',
                params: {
                    report: $route.params.report
                }
            }" to-text="New Report Widget" />
        </column>
    </columns>
</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {

    computed: {
        ...mapGetters('report', [
            'report'
        ])
    }

}
</script>